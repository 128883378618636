import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import { IconContext } from "react-icons"
import { FaMapMarker, FaDollarSign, FaChartArea, FaPlug } from "react-icons/fa"
import { Map, Marker, TileLayer } from "react-leaflet"
import {
  formatInfrastructure,
  renderDeposit,
  renderPrice,
} from "../utils/helpers"
import AuctionFullTeaser from "../components/AuctionFullTeaser"

export default ({ pageContext }) => {
  const auction = pageContext.auction
  const lot = pageContext.lot
  const lotId = pageContext.id
  const zoom = 16
  const options = {
    center: (lot.Location && [
      lot.Location.Latitude,
      lot.Location.Longitude,
    ]) || [54, 23],
    defaultBaseMap: "OpenStreetMap",
    zoom: zoom,
    height: 300,
  }

  return (
    <Layout>
      <SEO
        title={`${lotId}. ${lot.Address}`}
        description={`Купить участок по адресу ${lot.Address}`}
      />
      <div className="row mb-4">
        <div className="card p-0 my-2 border-0 w-100">
          <div className="card-body p-md-0">
            <h2 className="card-title mx-md-0">
              {lotId}. {lot.Address}
            </h2>
            <small className="text-muted pr-3">{lot.Number}</small>
            <IconContext.Provider value={{ color: "#333" }}>
              <FaMapMarker />
            </IconContext.Provider>
            <small className="text-muted pl-1">{lot.Address}</small>
            <div className="map-wrapper mt-2">
              {typeof window !== "undefined" && lot.Location && (
                <Map style={{ height: "300px", width: "100%" }} {...options}>
                  <TileLayer
                    attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  <Marker
                    position={[lot.Location.Latitude, lot.Location.Longitude]}
                  ></Marker>
                </Map>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 mb-2">
          <div className="col my-2">
            <p
              className="font-weight-lighter w-100 mb-0"
              style={{ color: "#747373", fontSize: ".8em" }}
            >
              Начальная цена:
            </p>
            <p>
              <FaDollarSign />
              {renderPrice(lot.StartPrice)}
            </p>
          </div>
          <div className="col my-2">
            <p
              className="font-weight-lighter w-100 mb-0"
              style={{ color: "#747373", fontSize: ".8em" }}
            >
              Депозит:
            </p>
            <p>
              <FaDollarSign />
              {renderDeposit(lot.StartPrice, lot.Deposit)}
            </p>
          </div>
          <div className="col my-2">
            <p
              className="font-weight-lighter w-100 mb-0"
              style={{ color: "#747373", fontSize: ".8em" }}
            >
              Расходы на документацию:
            </p>
            <p>
              <FaDollarSign />
              {renderPrice(lot.Documents)}
            </p>
          </div>
          <div className="col my-2">
            <FaChartArea /> {lot.Area}
          </div>
          <div className="col my-2">
            <FaPlug /> {formatInfrastructure(lot.Infrastructure)}
          </div>
        </div>
      </div>
      <div className="row">
        <AuctionFullTeaser auction={auction} />
      </div>
    </Layout>
  )
}
