import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { formatDate } from "../utils/helpers"
import { IconContext } from "react-icons"
import { FaMapMarker } from "react-icons/fa"

const AuctionFullTeaser = ({ auction }) => {
  return (
    <div className="card p-0 my-2 border-0">
      <div className="card-body p-md-0">
        <h2 className="card-title text-muted mx-md-0">
          <AniLink swipe direction="right" to={`/auction/${auction.id}`}>
            {auction.Title}
          </AniLink>
        </h2>
        <small className="text-muted pr-3">{formatDate(auction.Date)}</small>
        <IconContext.Provider value={{ color: "#333" }}>
          <FaMapMarker />
        </IconContext.Provider>
        <small className="text-muted pl-1">
          {auction.Region && auction.Region.Name}
        </small>
      </div>
    </div>
  )
}

export default AuctionFullTeaser
